import { disableVesselTypes, enableVesselTypes } from '@/api/aqvesseltypes';

const disableVesselType = {
	id: 'disableVesselType',
	selectionType: 'multiple',
	label: 'aqvesseltypes.actions.disableVesselType',
	functionality: 'UPDATE_AQVESSELTYPES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqvesseltypes.actions.disableVesselType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqvesseltypesid: registries[i].aqvesseltypesid
			});
		}

		const data = await disableVesselTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVesselType = {
	id: 'enableVesselType',
	selectionType: 'multiple',
	label: 'aqvesseltypes.actions.enableVesselType',
	functionality: 'UPDATE_AQVESSELTYPES',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqvesseltypes.actions.enableVesselType');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqvesseltypesid: registries[i].aqvesseltypesid
			});
		}

		const data = await enableVesselTypes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVesselType, enableVesselType]
};
